import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { DateField } from '@mui/x-date-pickers/DateField';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox,
  Stack,
  FormHelperText,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import { country_list } from '../constants';
import PlacesAutocomplete from '../components/places-autocomplete';
import ImageUploader from '../components/image-uploader';

export default function useFormGenerator({
  inputList,
  includeUniqueID,
  caseConfiguration,
  errorEmail,
  loginForm,
  authentication,
  onSubmit,
}) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
      email: '',

      // additionalData
      uniqueId: '',
      pin: '',
      claimantIdentity: 'individual',
      notificationType: 'direct',
      // notification: 'none'

      date: '',
      claimantFullName: '',

      certified: false,
      emailIds: [],
      playerIds: [],
      acceptPrivacy: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().max(255).required('First Name is required'),
      lastName: Yup.string().max(255).required('Last Name is required'),
      address: Yup.string().max(255).required('Address is required'),
      city: Yup.string().max(255).required('City is required'),
      state: Yup.string()
        .max(
          2,
          'State must be two characters long. Please use its abbreviated form.'
        )
        .required('State is required'),
      zipCode: Yup.string()
        .max(255)
        .required('Zip Code is required')
        .max(5, 'Zip Code can only be 5 digits long.'),
      phone: Yup.string()
        .max(255)
        .required('Phone is required')
        .max(10, 'Phone number can only be 10 digits long'),
      email: Yup.string().max(255).email().required('Email is required'),
      // uniqueId: Yup.string().when(['notificationType'], {
      //   is: (type) => type === 'direct',
      //   then: Yup.string()
      //     .min(8, 'Unique ID is a 8 digit code')
      //     .max(8, 'Unique ID is a 8 digit code')
      //     .required('Please add your Unique ID'),
      // }),
      // pin: Yup.string().when(['notificationType'], {
      //   is: (type) => type === 'direct',
      //   then: Yup.string()
      //     .min(6, 'PIN is at least 6 characters long')
      //     .required('Please add your PIN'),
      // }),

      date: Yup.string("Today's date is required")
        .required(`Today's date is required`)
        .test(
          'is-date-today',
          `The inserted date must be today's date`,
          (value) => {
            return dayjs(value).isSame(dayjs(), 'day');
          }
        ),
      claimantFullName: Yup.string()
        .max(255)
        .required(`Claimant's name is required`),
      certified: Yup.boolean().isTrue('Certification is required'),
      emailIds: Yup.array().when(['playerIds'], {
        is: (_playerIds) => _playerIds?.length === 0,
        then: Yup.array()
          .of(Yup.string().email('One of the provided emails is invalid'))
          .min(1, 'At least an email or player id needs to be provided'),
        otherwise: Yup.array().of(
          Yup.string().email('One of the provided emails is invalid')
        ),
      }),
      playerIds: Yup.array().of(Yup.string()),
      acceptPrivacy: Yup.boolean().isTrue('Must accept our privacy policy'),
    }),
    onSubmit,
  });

  const updateInitialValues = (_claimant) => {
    formik.setValues({
      firstName: _claimant?.billingFirstName,
      middleInitial: _claimant?.middleInitial,
      lastName: _claimant?.billingLastName,
      country: _claimant?.country,
      address: _claimant?.billingStreet,
      city: _claimant?.billingCity,
      state: _claimant?.billingState,
      zipCode: _claimant?.billingZip,
      phone: _claimant?.phone,
      email: _claimant?.email,
      certified: _claimant?.certified,
      playerIds: _claimant?.playerIds,
      emailIds: _claimant?.emailIds,
    });
  };

  const generateForm = useCallback(
    () => (
      <React.Fragment>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="First Name"
            name="firstName"
            variant="outlined"
            placeholder="John"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Last Name"
            name="lastName"
            variant="outlined"
            placeholder="Doe"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <PlacesAutocomplete label="Address Line 1" formik={formik} /> */}
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label={'Address Line 1'}
            value={formik.values.address}
            onChange={formik.handleChange}
            name="address"
            variant="outlined"
            error={Boolean(formik.touched.address && formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="City"
            name="city"
            variant="outlined"
            placeholder="Red Bank"
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="State"
            name="state"
            variant="outlined"
            placeholder="New Jersey"
            value={formik.values.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.state && formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Zip Code"
            name="zipCode"
            variant="outlined"
            placeholder="XXXXX"
            value={formik.values.zipCode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Email"
            name="email"
            variant="outlined"
            placeholder="johndoe@email.com"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Phone Number"
            name="phone"
            variant="outlined"
            placeholder="1235550000"
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography>
            Enter your www.pulsz.com and/or www.pulszbingo.com Player ID(s) (if
            known). If multiple, separate each one with a comma (,).
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            sx={{ pr: { xs: 0, sm: 3 } }}
            label="Player ID(s)"
            name="playerIds"
            variant="outlined"
            placeholder="198439085, 09382123"
            value={formik.values.playerIds.join(', ')}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              const value = e.target.value
                ? e.target.value.split(',').map((v) => v.trim())
                : [];
              formik.setFieldValue('playerIds', value);
            }}
            error={Boolean(formik.touched.playerIds && formik.errors.playerIds)}
            helperText={formik.touched.playerIds && formik.errors.playerIds}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography>
            Enter all email addresses associated with www.pulsz.com and/or
            www.pulszbingo.com accounts. If multiple, separate each one with a
            comma (,).
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Email Addresses"
            name="emailIds"
            variant="outlined"
            placeholder="example@email.com"
            value={formik.values.emailIds.join(', ')}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              const value = e.target.value
                ? e.target.value.split(',').map((v) => v.trim())
                : [];
              formik.setFieldValue('emailIds', value);
            }}
            error={Boolean(formik.touched.emailIds && formik.errors.emailIds)}
            helperText={formik.touched.emailIds && formik.errors.emailIds}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Typography variant="h6">
            Settlement Class Member Affirmation
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControl
            error={Boolean(formik.touched.certified && formik.errors.certified)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="certified"
                  checked={formik.values.certified}
                  onChange={formik.handleChange}
                  sx={{
                    color:
                      formik.touched.certified && formik.errors.certified
                        ? 'error.main'
                        : 'unset',
                  }}
                />
              }
              label={
                <p>
                  By submitting this Claim Form you affirm under penalty of
                  perjury that, to the best of your knowledge, the Player ID(s)
                  and the email address(es) listed above are yours.
                </p>
              }
            />
            {Boolean(formik.touched.certified && formik.errors.certified) && (
              <FormHelperText>
                {formik.touched.certified && formik.errors.certified}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Claimant Full Name"
            name="claimantFullName"
            variant="outlined"
            placeholder="Robert"
            value={formik.values.claimantFullName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.claimantFullName && formik.errors.claimantFullName
            )}
            helperText={
              formik.touched.claimantFullName && formik.errors.claimantFullName
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <DateField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label="Today's Date"
            name="date"
            variant="outlined"
            placeholder="mm/dd/yyyy"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            format="MM/DD/YYYY"
            onChange={(value) => {
              formik.setFieldValue('date', value);
            }}
            error={Boolean(formik.touched.date && formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="acceptPrivacy"
                checked={formik.values.acceptPrivacy}
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                      ? 'error.main'
                      : 'unset',
                }}
              />
            }
            label={
              <Typography>
                By clicking “Submit Claim” you are confirming that you have read
                and agree to our <Link to="/privacy">Privacy Policy</Link>
              </Typography>
            }
          />
          {Boolean(
            formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
          ) && (
            <FormHelperText
              sx={{
                color:
                  formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                    ? 'error.main'
                    : 'unset',
              }}
            >
              {formik.touched.acceptPrivacy && formik.errors.acceptPrivacy}
            </FormHelperText>
          )}
        </Grid>
      </React.Fragment>
    ),
    [formik]
  );

  return [generateForm, formik.handleSubmit, updateInitialValues];
}
