import React, { useState, useMemo, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { graphql, Link, navigate } from 'gatsby';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import { theme } from '../theme';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';
import ImageUploader from '../components/image-uploader';
import SelectionGroup from '../components/selection-group';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import useFormGenerator from '../hooks/useFormGenerator';
import {
  useFileUploader,
  signUp,
  signIn,
  signOutFromFirebase,
} from '../hooks/useFirebase';
import {
  submitClaim,
  getClaimantData,
  getClaimantCredentials,
  submitClaimForHashing,
} from '../services/claims.service';
import { useSessionStorage } from '../hooks/useSessionStorage';
import useAuth from '../hooks/useAuthentication';
import { useClaimStatus, useSnackbar } from '../store';
import { SnackBar } from '../components/snackbar';
import { claimTypeValues } from '../constants';
import { parseFirebaseError } from '../utils/firebase-error-parser';
import logAnalyticsEvent from '../utils/log-analytics-event';

const ClaimForm = ({ data }) => {
  useEffect(() => {
    logAnalyticsEvent('claim_form_page_visit', {
      caseId: data?.template?.caseID,
    });
  }, []);
  const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY;
  const { instructions, caseName, caseID, authentication, claimInfo } =
    data?.template;
  const caseConfiguration = data?.caseConfiguration;

  const { openSnackbar } = useSnackbar();
  const { setClaimID } = useClaimStatus();
  const fileUploader = useFileUploader();
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [legalRepresentativeFiles, setLegalRepresentativeFiles] = useState([]);

  const submitUndefinedPurchaserClaim = async (request, headers) => {
    const {
      data: { orderID, key },
    } = await submitClaimForHashing({ job: request }, headers);
    await signUp(`${orderID}${caseID}@claimscore.ai`, key);
    await submitClaim({ job: request, orderID, key });
    return { data: { orderID } };
  };

  const [formGenerator, submitHandler, updateInitialValues] = useFormGenerator({
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);
        if (
          ['priced-pop', 'non-priced-pop'].includes(values.claimType) &&
          files.length === 0
        ) {
          openSnackbar('Please upload your Proof of Purchase', 'error');
          setLoading(false);
          return;
        }
        const fileUploads = files.map((file) => {
          const fileUrl = `/pop/pending/${caseID}/${uuid()}.${
            file.name.split('.')[1]
          }`;
          return fileUploader(fileUrl, file).then(
            (snapshot) => snapshot.metadata.fullPath
          );
        });

        if (
          values.claimantIdentity !== 'individual' &&
          legalRepresentativeFiles.length === 0
        ) {
          openSnackbar('Legal representation files are required', 'error');
          setLoading(false);
          return;
        }

        const LegalRepresentativeUploads = legalRepresentativeFiles.map(
          (file) => {
            const fileUrl = `/legal/${caseID}/${uuid()}.${
              file.name.split('.')[1]
            }`;
            return fileUploader(fileUrl, file).then(
              (snapshot) => snapshot.metadata.fullPath
            );
          }
        );

        let popFileUrls;
        let representationFileUrls;

        try {
          popFileUrls = await Promise.all(fileUploads);
          representationFileUrls = await Promise.all(
            LegalRepresentativeUploads
          );
        } catch (error) {
          openSnackbar(
            'There was an error uploading the files.  Please try reloading the page.',
            'error'
          );
          setLoading(false);
          return;
        }

        const claimantMustIdentify = values.notificationType === 'direct';

        const playerIds = values.playerIds.filter((v) => v);
        const emailIds = values.emailIds.filter((v) => v);

        const credentialsRequestBody = {
          caseID,
          playerIDs: playerIds,
          emails: emailIds,
        };

        const credentialsHeaders = {
          'x-recaptcha-token': captcha,
        };

        let claimID = '';
        let pin = '';

        try {
          const claimantCredentials = await getClaimantCredentials(
            credentialsRequestBody,
            credentialsHeaders
          );
          claimID = claimantCredentials.data.claimID;
          pin = claimantCredentials.data.pin;
        } catch (e) {
          const statusResponse = e.response.status;
          let errorMessage = '';

          switch (statusResponse) {
            case 409: {
              errorMessage =
                'A claim was already made for the account associated with the PlayerID and/or email address you provided. You may not submit another claim for this account. Please use the contact us form if you believe this is an error.';
              break;
            }
            case 404: {
              errorMessage =
                'The PlayerID and/or email address you provided is not valid. You must provide at least one valid PlayerID and/or email address associated with a www.funzpoints.com account. Please use the contact us form if you believe this is an error.';
              break;
            }
            case 400: {
              errorMessage =
                'An unexpected error ocurred. Please use the contact us form or try again later.';
              break;
            }
            default: {
              errorMessage = 'Something went wrong. Please try again later.';
            }
          }
          logAnalyticsEvent('claim_form_aux_endpoint_error', {
            status_response: statusResponse,
            caseId: caseID,
          });
          openSnackbar(errorMessage, 'error');
          setLoading(false);
          return;
        }

        const { directNotice, pop, nonPop } = claimTypeValues;

        const request = {
          caseID,
          caseName,
          claimSearch: {
            orderDate: new Date().toISOString(),
            billingFirstName: values.firstName,
            billingLastName: values.lastName,
            billingStreet: values.address,
            billingStreet2: values.address2,
            billingCity: values.city,
            billingState: values.state,
            billingZip: values.zipCode,
            phone: values.phone,
            email: values.email,
            claimItemType: claimantMustIdentify
              ? directNotice
              : files[0]
              ? pop
              : nonPop,
            popLinks: popFileUrls,
            referrer: document.referrer,
            productData: {
              date: values.purchaseDate
                ? dayjs(values.purchaseDate).format('MM/DD/YYYY')
                : '',
              location: values.purchaseLocation,
            },
            additionalData: {
              acceptedPrivacy: values.acceptedPrivacy,
              todayDate: values.todayDate
                ? dayjs(values.todayDate).format('MM/DD/YYYY')
                : '',
              date: dayjs(values.date).format('MM/DD/YYYY'),
              phone2: values.phone2,
              claimantIdentity: values.claimantIdentity,
              claimantFullName: values.claimantFullName,
              legalRepresentativeName: values.legalRepresentativeName,
              legalRepresentativeCapacity: values.legalRepresentativeCapacity,
              legalRepresentativeUploads: representationFileUrls,
              certified: values.certified,
              playerIds,
              emailIds,
            },
          },
        };

        const headers = {
          'x-recaptcha-token': captcha,
        };

        if (claimantMustIdentify) {
          if (!pin || !claimID) {
            return;
          }
          try {
            await signIn(`${claimID}${caseID}@claimscore.ai`, pin);
          } catch (err) {
            actions.setErrors(parseFirebaseError(authentication));
            setLoading(false);
            return;
          }
        }

        let data;
        if (claimantMustIdentify) {
          data = await submitClaim({
            job: request,
            pin: pin,
            uniqueId: claimID,
          });
        } else {
          // data = await submitUndefinedPurchaserClaim(request, headers);
        }

        const { orderID } = data;
        setClaimID(orderID);
        logAnalyticsEvent('claim_form_submit_success', {
          claimId: orderID,
          caseId: caseID,
        });
        navigate('/claim-check');
        // TODO: check that orderID is returned and captcha is sent
        return;
      } catch (e) {
        const errorMessage =
          'Experiencing an unexpected error. Please try reloading the page.';
        openSnackbar(errorMessage, 'error');
        setLoading(false);
        logAnalyticsEvent('claim_form_submit_error', {
          caseId: caseID,
        });
      }
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <form onSubmit={submitHandler}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
                Submit a Claim
              </Typography>
            </Box>
            <Box sx={{ mb: 8 }}>
              <Breadcrumbs separator="→" aria-label="breadcrumb">
                {[
                  <Link key="1" to="/">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '16px',
                          md: '32px',
                          color: 'neutral.500',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </Link>,
                  Array.isArray(data.template.instructions) &&
                    data.template.instructions.length > 0 && (
                      <Link key="2" to="/claim-instructions">
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '16px',
                              md: '32px',
                              color: 'neutral.500',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          Claim Instructions
                        </Typography>
                      </Link>
                    ),
                  <Typography
                    key="3"
                    sx={{ fontSize: { xs: '16px', md: '32px' } }}
                  >
                    Claim Form
                  </Typography>,
                ]}
              </Breadcrumbs>
            </Box>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Claim Form And Instructions
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item>
                    <Stack direction="column" spacing={3}>
                      <Typography variant="primary">
                        THIS CLAIM FORM MUST BE SUBMITTED ONLINE OR POSTMARKED
                        BY JANUARY 29, 2024. THE CLAIM FORM MUST BE SIGNED AND
                        MEET ALL CONDITIONS OF THE SETTLEMENT AGREEMENT.
                      </Typography>
                      <Typography variant="primary">
                        The Settlement Administrator will review your Claim
                        Form. If accepted, you will receive a share of the
                        Settlement Fund. This process takes time, please be
                        patient. If you have any questions please{' '}
                        <Link to="/contact">Contact&nbsp;Us</Link> or if you
                        would like to estimate your share of the Settlement
                        Fund, please read the{' '}
                        <Link to="https://firebasestorage.googleapis.com/v0/b/claimscore---staging.appspot.com/o/documents%2F6f022df1-ce09-43fc-8344-049a3a300205%2Fc88ce2e5-f93a-4038-867b-9b397f6c3346.pdf?alt=media&token=8f13c738-07fb-40f1-bf79-f56fd1e44a5e">
                          Payout&nbsp;Estimate&nbsp;Information
                        </Link>
                        .
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Contact Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography>
                  Fill out each section of this form and sign where indicated.
                </Typography>
                <Grid container sx={{ mt: 3 }}>
                  {formGenerator()}
                </Grid>
              </CardContent>
            </Card>

            {RECAPTCHA_KEY && (
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => {
                  setCaptcha(value);
                }}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <Button
                type="submit"
                fullWidth={!isLG}
                variant="contained"
                sx={{
                  my: 2,
                  color: 'background.paper',
                  px: { md: '44px' },
                  py: 2,
                  fontSize: '15px',
                  fontWeight: 400,
                }}
                disableElevation
              >
                Submit Claim Form
              </Button>
            </Box>
          </form>
        </SnackBar>
      </Layout>
    </LocalizationProvider>
  );
};

export default ClaimForm;

export const Head = ({ data }) => (
  <title>ClaimForm | {data.template.caseName}</title>
);

export const query = graphql`
  query FormPage($id: StringQueryOperatorInput = {}) {
    caseConfiguration(id: $id) {
      billingCity {
        editable
        nameToShow
      }
      billingFirstName {
        editable
        nameToShow
      }
      billingLastName {
        editable
        nameToShow
      }
      billingState {
        editable
        nameToShow
      }
      billingStreet {
        editable
        nameToShow
      }
      billingStreet2 {
        editable
        nameToShow
      }
      billingZip {
        editable
        nameToShow
      }
      email {
        editable
        nameToShow
      }
      phone {
        editable
        nameToShow
      }
    }
    template(id: $id) {
      caseID
      caseName
      claimInfo {
        type
        wrapper
        inputs
        text
        group
        options {
          id
          description
          value
        }
      }
      instructions {
        type
      }
      authentication {
        loginForm
        idFieldName
        secretFieldName
      }
    }
  }
`;
